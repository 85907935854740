import React, { FC, useEffect, useReducer, useState, useRef, useMemo } from 'react';
import { Modal, Table, Button, List, Input, Dropdown, Menu, Radio, Checkbox } from 'antd';
import {
  OrderTemplate,
  OrderTemplateFulfillmentType,
  OrderTemplateResponsePropertiesItemsInner,
  OtiBox,
  OtiDeviceKit,
  OtiShippingLabel,
} from '@tassoinc/core-api-client';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, CloseCircleFilled } from '@ant-design/icons';

import { IKitItem, useKitItems } from 'context/kitItems';
import { Analyte } from 'context/analytes';
import { GetAnalytesParams } from 'api/analytes';
import { makeReducer } from 'utils/functions';

import useAnalyteSelectModal from 'hooks/useAnalyteSelectModal';
import { NEW_OT_ID, useOrderTemplates } from 'context/orderTemplates';
import useSelectKitItemsModal from 'hooks/useKitItemsSimpleModal';
import useCustomAttributeSelectModal from 'hooks/useCustomAttributeSelectModal';
import { ICustomAttributeDefinition, useCustomAttributeDefinitions } from 'context/customAttributeDefinitions';
import { KIT_ITEM_TYPES_LABELS, OrderTemplateSchemaTypes, PACKAGE1_TYPE_LABELS, Package1Type } from 'constants/index';
import { ColumnsType } from 'antd/lib/table';
import { IProject } from 'context/projects';
import useOrderTemplateSelectModal from 'hooks/useOrderTemplateSelectModal';
import { getDefaultOrderTemplate } from 'utils/orderTemplates';
import EditShippingModal from './EditShippingModal';
import Quantity from './Quantity';
import {
  findOrderTemplateItemsBySchemaType,
  hasItems,
  canAddChildren,
  mapSchemaTypeToLabel,
  otiFactory,
  newOrderTemplate,
  mapName,
  findItemByOrderTemplateId,
  formatError,
  innerDeleteItem,
} from './functions';
import { Spinner } from '..';

interface TableRow {
  title: string;
  key: string;
  dataRef: any;
  name: string;
  quantity: React.JSX.Element;
  canHaveChildren: boolean;
  children?: React.JSX.Element;
  nestingLevel: number;
  itemTypeLabel: string;
}

type TableDataSource = TableRow[] | undefined;

interface OrderTemplateState {
  template: OrderTemplate;
  parentNode: OrderTemplateResponsePropertiesItemsInner | null; // set when adding something to an existing item
  customAttributeDefinitions: ICustomAttributeDefinition[];
  loading: boolean;
}

const initialState = (): OrderTemplateState => ({
  template: newOrderTemplate(''),
  parentNode: null,
  customAttributeDefinitions: [],
  loading: false,
});

interface IOrderTemplateModalProps {
  visible: boolean;
  orderTemplate: OrderTemplate;
  analytes: Analyte[];
  customerId: string;
  project: IProject;
  templatesForProject: OrderTemplate[];
  getAnalytes: (params: GetAnalytesParams, callback?: any | undefined) => Promise<void>;
  onClose: () => void;
}

const OrderTemplateModal: FC<IOrderTemplateModalProps> = ({
  visible,
  orderTemplate,
  analytes,
  customerId,
  project,
  templatesForProject,
  getAnalytes,
  onClose,
}) => {
  const isCreateMode = orderTemplate.id === NEW_OT_ID;

  const errorListHeader = useRef<HTMLHeadingElement>(null);

  const [state, setState] = useReducer(makeReducer<OrderTemplateState>(), initialState());
  const { createOrderTemplate, updateOrderTemplate } = useOrderTemplates();
  const { customAttributeDefinitions, getCustomAttributeDefinitions } = useCustomAttributeDefinitions();

  const { getKitItem, kitItems } = useKitItems();

  const r777LinkExists = state.template.conditionalOrderTemplates && 'R777' in state.template.conditionalOrderTemplates;

  // genericKitItemInfo stores information about generic kit items gathered only for display purposes.
  // To know what generic order template item really represents it's necessary to know its kitItemType.
  // Order templates don't store this kitItemType, so the actual kit items need to fetched and inspected.
  // The results of that fetching are stored in this state variable.
  const [genericKitItemInfo, setGenericKitItemInfo] = useState<{ kitItemId: string; kitItemType: string }[]>([]);

  const updateTemplateInState = (ot: OrderTemplate): void => {
    setState({ template: JSON.parse(JSON.stringify(ot)) });
  };

  /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["parent"] }] */
  const addAnalytes = (analyteIds: string[], parent: OrderTemplateResponsePropertiesItemsInner | null): void => {
    let items: OrderTemplateResponsePropertiesItemsInner[];
    let baseOrderTemplateId: string;

    if (parent && hasItems(parent)) {
      parent.items = parent.items ?? [];
      items = parent.items;
      baseOrderTemplateId = parent.orderTemplateId!;
    } else {
      state.template.items = state.template.items ?? [];
      items = state.template.items;
      baseOrderTemplateId = 'root';
    }

    for (const analyteId of analyteIds) {
      const analyte = {
        coreApiId: analyteId,
        coreApiType: 'analyte',
        schemaType: OrderTemplateSchemaTypes.ANALYTE,
        orderTemplateId: `${baseOrderTemplateId}.${items.length + 1}`,
      };

      items.push(analyte);
    }

    updateTemplateInState(state.template);
  };

  const addKitItem = (kitItem: IKitItem, parent?: OtiBox | OtiDeviceKit) => {
    if (parent) {
      if (!parent.items) {
        parent.items = [];
      }
      parent.items.push(otiFactory(kitItem, `${parent.orderTemplateId}.${parent.items.length + 1}`));
    } else {
      state.template?.items?.push(otiFactory(kitItem, `root.${state.template?.items.length + 1}`));
    }

    updateTemplateInState(state.template);
  };

  const addAttribute = (attributeId: string) => {
    if (!state.template) {
      return;
    }

    const updatedTemplate = state.template;

    if (!updatedTemplate.customAttributeDefinitionIds) {
      updatedTemplate.customAttributeDefinitionIds = [];
    }

    if (updatedTemplate.customAttributeDefinitionIds.includes(attributeId)) {
      return;
    }

    updatedTemplate.customAttributeDefinitionIds.push(attributeId);

    updateTemplateInState(updatedTemplate);
  };

  const addTemplate = (templateId: string) => {
    if (!state.template) {
      return;
    }

    const updatedTemplate = state.template;

    if (!updatedTemplate.conditionalOrderTemplates) {
      updatedTemplate.conditionalOrderTemplates = {};
    }

    updatedTemplate.conditionalOrderTemplates = { R777: templateId };

    updateTemplateInState(updatedTemplate);
  };

  const onAnalyteSelected = (analyteIds: string[]) => {
    addAnalytes(analyteIds, state.parentNode);
  };

  const onKitItemSelected = (kitItemId: string) => {
    getKitItem(kitItemId, undefined, (kitItem: IKitItem | null) => {
      if (kitItem) {
        addKitItem(kitItem, state.parentNode ?? undefined);
      }
    });
  };

  const onAttributeSelected = (attributeIds: string[]) => {
    attributeIds.map((attributeId) => addAttribute(attributeId));
  };

  const onAlternativeR777TemplateSelected = (templateId: string) => {
    addTemplate(templateId);
  };

  const { Modal: KitItemModal, openSelectModal: openKitItemSelectModal } = useSelectKitItemsModal({
    fetchKitItems: true,
    onSelectKitItem: onKitItemSelected,
  });

  const [shippingLabel, setShippingLabel] = useState<OtiShippingLabel | null>(null);
  const [fulfillmentType, setFulfillmentType] = useState<OrderTemplateFulfillmentType>(
    OrderTemplateFulfillmentType.BioTouch
  );

  const defaultOrderTemplateId = useMemo(() => {
    const defaultTemplate = getDefaultOrderTemplate(templatesForProject, project.orderTemplatesViewOrder);

    return defaultTemplate?.id;
  }, [project]);

  const { Modal: AnalyteSelectModal, openModal: openProjectAnalyteLinkModal } =
    useAnalyteSelectModal(onAnalyteSelected);
  const [showEditShippingModal, setShowEditShippingModal] = useState(false);
  const [showFulfillmentConfirmationModal, setShowFulfillmentConfirmationModal] = useState(false);

  const { Modal: CustomAttributeSelectModal, openModal: openCustomAttributeSelectModal } =
    useCustomAttributeSelectModal(onAttributeSelected);

  const { Modal: OrderTemplateSelectModal, openModal: openOrderTemplateSelectModal } = useOrderTemplateSelectModal(
    onAlternativeR777TemplateSelected
  );

  const handleClose = (): void => {
    onClose();
  };

  const handleSubmit = async (): Promise<void> => {
    let savedTemplate: OrderTemplate | null = null;

    const sourceTemplate = state.template;
    // spin
    setState({ loading: true });

    if (isCreateMode) {
      await createOrderTemplate(
        {
          name: sourceTemplate.name,
          ...(sourceTemplate.description ? { description: sourceTemplate.description } : {}),
          items: sourceTemplate.items ?? [],
          fulfillmentType: sourceTemplate.fulfillmentType,
          customAttributeDefinitionIds: sourceTemplate.customAttributeDefinitionIds,
          projectId: project.id,
          isCustomerOrderable: sourceTemplate.isCustomerOrderable,
          conditionalOrderTemplates: sourceTemplate.conditionalOrderTemplates,
        },
        undefined,
        (ot) => {
          savedTemplate = ot;
        }
      );
    } else {
      await updateOrderTemplate(
        sourceTemplate.id,
        {
          name: sourceTemplate.name,
          items: sourceTemplate.items ?? [],
          ...(sourceTemplate.description ? { description: sourceTemplate.description } : {}),
          customAttributeDefinitionIds: sourceTemplate.customAttributeDefinitionIds,
          fulfillmentType: sourceTemplate.fulfillmentType,
          isDeleted: sourceTemplate.isDeleted,
          isReplacement: sourceTemplate.isReplacement,
          salesOrder: sourceTemplate.salesOrder,
          isCustomerOrderable: sourceTemplate.isCustomerOrderable,
          conditionalOrderTemplates: sourceTemplate.conditionalOrderTemplates,
        },
        undefined,
        (ot) => {
          savedTemplate = ot;
        }
      );
    }

    if (savedTemplate) {
      updateTemplateInState({ ...sourceTemplate, ...(savedTemplate as OrderTemplate) });

      const errorCount = (savedTemplate as OrderTemplate).errors?.length ?? 0;
      if (errorCount === 0) {
        handleClose();
      } else {
        // Scroll to the list of errors to bring them to user's attention.
        // eslint-disable-next-line no-lonely-if
        if (errorListHeader.current) {
          errorListHeader.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
    setState({ loading: false });
  };

  const selectAnalyte = (parent?: { dataRef: OrderTemplateResponsePropertiesItemsInner }) => {
    setState({ parentNode: parent?.dataRef });
    openProjectAnalyteLinkModal('', [], onAnalyteSelected);
  };

  const selectKitItem = (parent?: { dataRef: OrderTemplateResponsePropertiesItemsInner }) => {
    setState({ parentNode: parent?.dataRef });
    openKitItemSelectModal();
  };

  const selectCustomAttribute = (parent?: { dataRef: OrderTemplateResponsePropertiesItemsInner }) => {
    setState({ parentNode: parent?.dataRef });
    openCustomAttributeSelectModal(customerId, onAttributeSelected);
  };

  const selectAlternativeR777Template = (parent?: { dataRef: OrderTemplateResponsePropertiesItemsInner }) => {
    setState({ parentNode: parent?.dataRef });
    openOrderTemplateSelectModal(project.id, state.template, onAlternativeR777TemplateSelected);
  };

  const deleteItem = (target: OrderTemplateResponsePropertiesItemsInner): void => {
    state.template.items = innerDeleteItem(target, state.template.items ?? []);

    updateTemplateInState(state.template);
  };

  const deleteR777Link = (): void => {
    if (r777LinkExists) {
      delete (state.template.conditionalOrderTemplates as any).R777;
    }
    updateTemplateInState(state.template);
  };

  const deleteAttribute = (attributeId: string): void => {
    state.template.customAttributeDefinitionIds = state.template.customAttributeDefinitionIds?.filter(
      (id) => id !== attributeId
    );

    updateTemplateInState(state.template);
  };

  const handleNameChange = (e: any): void => {
    state.template.name = e.target.value;

    updateTemplateInState(state.template);
  };

  const handleIsCustomerOrderableChange = (e: any): void => {
    state.template.isCustomerOrderable = e.target.checked === true;
    updateTemplateInState(state.template);
  };

  const handleDescriptionChange = (e: any): void => {
    state.template.description = e.target.value;

    updateTemplateInState(state.template);
  };

  const handleFulfillmentTypeChange = (type: OrderTemplateFulfillmentType): void => {
    updateTemplateInState({ ...state.template, fulfillmentType: type });
  };

  useEffect(() => {
    setState(initialState()); // reset state

    if (visible) {
      getCustomAttributeDefinitions(customerId);
      getAnalytes({});
      updateTemplateInState(orderTemplate);
    }
  }, [visible]);

  const changeQuantity = (orderTemplateId: string, value: number): void => {
    const item = findItemByOrderTemplateId(orderTemplateId, state.template.items!);

    if (item) {
      item.quantity = value;
      updateTemplateInState(state.template);
    }
  };

  const getTemplateItemLabel = (kitItemId: string, schemaType: string): string => {
    if (schemaType === OrderTemplateSchemaTypes.GENERIC) {
      const foundKitItem = genericKitItemInfo.find((item) => item.kitItemId === kitItemId);
      if (foundKitItem) {
        return (
          KIT_ITEM_TYPES_LABELS[foundKitItem.kitItemType as keyof typeof KIT_ITEM_TYPES_LABELS] ??
          (foundKitItem.kitItemType || 'KIT ITEM TYPE NOT SET')
        );
      }
    }

    return mapSchemaTypeToLabel(schemaType);
  };

  const renderTreeNodes = (
    data: OrderTemplateResponsePropertiesItemsInner[] | undefined,
    nestingLevel = 0
  ): TableDataSource => {
    if (!data) {
      return undefined;
    }

    return data.map((item) => {
      let children = renderTreeNodes((item as any).items, nestingLevel + 1) as any as React.JSX.Element | undefined;
      children = !children || (Array.isArray(children) && children.length === 0) ? undefined : children;

      return {
        title: item.coreApiType,
        key: item.orderTemplateId!,
        dataRef: item,
        name: mapName(item, analytes, kitItems),
        itemTypeLabel: getTemplateItemLabel(item.coreApiId, item.schemaType),
        quantity: (
          <Quantity
            value={item.quantity}
            orderTemplateId={item.orderTemplateId!}
            onChange={changeQuantity}
            readOnly={item.schemaType === OrderTemplateSchemaTypes.PACKING_INSTRUCTIONS}
          />
        ),
        nestingLevel,
        canHaveChildren: canAddChildren(item),
        ...(children ? { children } : {}),
      };
    });
  };

  const columns: ColumnsType<TableRow> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '98%',
      render(_value, record) {
        return (
          <div className="itemName">
            {record.name}
            <div className="itemType">{record.itemTypeLabel}</div>
          </div>
        );
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: '1%',
      key: 'quantity',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      width: '1%',
      render: (_value, record) => (
        <div className="actions">
          <Button
            shape="circle"
            type="link"
            disabled={record.dataRef.schemaType.toLowerCase() !== OrderTemplateSchemaTypes.SHIPPING_LABEL.toLowerCase()}
            onClick={() => {
              if (record.dataRef.schemaType.toLowerCase() === OrderTemplateSchemaTypes.SHIPPING_LABEL.toLowerCase()) {
                setShippingLabel(record.dataRef as OtiShippingLabel);
                setShowEditShippingModal(true);
              }
            }}
            icon={<EditOutlined />}
          />

          <Dropdown
            disabled={!record.canHaveChildren}
            arrow
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item onClick={() => selectAnalyte(record)}>Analyte</Menu.Item>
                <Menu.Item onClick={() => selectKitItem(record)}>Kit item</Menu.Item>
              </Menu>
            }
          >
            <Button shape="circle" type="link" icon={<PlusCircleOutlined />} />
          </Dropdown>
          <Button
            shape="circle"
            type="link"
            onClick={() => deleteItem(record.dataRef)}
            danger
            icon={<DeleteOutlined />}
          />
        </div>
      ),
    },
  ];

  const attributeColumns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: 'Required?',
      dataIndex: 'required',
      width: '20%',
      key: 'required',
      render: (required: boolean) => (required ? 'Yes' : 'No'),
    },
    {
      title: 'Action',
      key: 'action',
      width: '30%',
      render: (record) => (
        <Button
          shape="circle"
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => deleteAttribute(record.key)}
          danger
        />
      ),
    },
  ];

  const templateErrors = state.template?.errors ?? [];

  /**
   * Whenever order template changes rebuild the information about generic kit items.
   * Whatever kit items are missing will be fetched from the API. Otherwise kit items already
   * in memory will be used. No unnecessary HTTP requests.
   */
  useEffect(() => {
    const genericKitItems = findOrderTemplateItemsBySchemaType(state.template.items ?? [], 'OtiGenericKitItem');
    if (genericKitItems.length === 0) {
      setGenericKitItemInfo([]);
      return;
    }

    const kitItemIds = genericKitItems.map((item) => item.coreApiId);

    // Check if the global kitItems array from context is populated.
    // Try to extract any matches from it. It's being used as a cache layer here.
    const loadedKitItems = kitItemIds.reduce<IKitItem[]>((acc, id) => {
      const theItem = kitItems.find((k) => k.id === id);
      if (theItem) {
        return [...acc, theItem];
      }
      return acc;
    }, []);

    const loadedKitItemIds = loadedKitItems.map((item) => item.id);
    const remainingKitItemIds = kitItemIds.filter((id) => !loadedKitItemIds.includes(id));

    // There are still some kit items that need to be loaded since they're not in memory yet.
    if (remainingKitItemIds.length > 0) {
      // useEffect() doesn't do async code, so create a local wrapper to make things work.
      const fetchAndUpdateState = async (): Promise<void> => {
        // getById() requests are used instead of get() to avoid context state updates.
        // The context handler could be improved instead but this bit of code is unlikely to be hit
        // frequently, so getById() requests work just fine.
        const promises = remainingKitItemIds.map(
          (id) =>
            new Promise<IKitItem | null>((r) =>
              getKitItem(id, {}, (item) => {
                r(item);
              })
            )
        );

        // It's a best effort scenario. The overall objective of all this code is to display nice labels.
        // If some requests fail those failures are of low importance and have low impact.
        const results = await Promise.allSettled(promises);

        for (const result of results) {
          if (result.status === 'fulfilled' && result.value) {
            loadedKitItems.push(result.value);
          }
        }

        setGenericKitItemInfo(
          loadedKitItems.map((item) => ({ kitItemId: item.id, kitItemType: item.kitItemType ?? '' }))
        );
      };

      fetchAndUpdateState();
    } else {
      setGenericKitItemInfo(
        loadedKitItems.map((item) => ({ kitItemId: item.id, kitItemType: item.kitItemType ?? '' }))
      );
    }
  }, [state.template]);

  const uspsToPatientLabelFound = state.template.items?.some(
    (item: any) =>
      item.schemaType === 'OtiShippingLabel' &&
      item.destinationType === 'patient' &&
      PACKAGE1_TYPE_LABELS[item.service as Package1Type].includes('USPS')
  );

  return (
    <>
      {AnalyteSelectModal}
      {KitItemModal}
      {CustomAttributeSelectModal}
      {OrderTemplateSelectModal}
      <EditShippingModal
        visible={showEditShippingModal}
        value={shippingLabel}
        onCancel={() => {
          setShowEditShippingModal(false);
        }}
        onSave={() => {
          setShowEditShippingModal(false);
        }}
        project={project}
      />

      <Modal
        visible={visible}
        closable={false}
        wrapClassName="Modal OrderTemplateModal"
        title={isCreateMode ? 'Create Order Template' : 'Edit Order Template'}
        centered
        okText="Save"
        cancelText="Cancel"
        onOk={handleSubmit}
        onCancel={handleClose}
        width={720}
        zIndex={10}
      >
        <Spinner spinning={state.loading}>
          <div style={{ width: '100%' }}>
            {templateErrors.length > 0 && (
              <>
                <h3 ref={errorListHeader}>Errors ({templateErrors.length})</h3>

                <List
                  data-testid="ot-errors"
                  className="templateErrors"
                  style={{ width: '100%' }}
                  size="small"
                  dataSource={templateErrors}
                  renderItem={(item) => (
                    <List.Item data-testid="li-ot-error">
                      <CloseCircleFilled style={{ color: '#ff4d4f' }} /> {formatError(item)}
                    </List.Item>
                  )}
                />
              </>
            )}

            <h3>
              Name
              <Input placeholder="Enter name" value={state.template.name} onInput={handleNameChange} />
            </h3>

            <h3>
              Description
              <Input.TextArea
                placeholder="Enter description"
                value={state.template.description}
                onInput={handleDescriptionChange}
                rows={3}
              />
            </h3>

            <h3>
              Items
              <Dropdown
                arrow
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item onClick={() => selectAnalyte(undefined)}>Analyte</Menu.Item>
                    <Menu.Item data-testid="add-kit-item" onClick={() => selectKitItem(undefined)}>
                      Kit item
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type="link" data-testid="button-add-item">
                  <PlusCircleOutlined />
                </Button>
              </Dropdown>
            </h3>

            <Table
              id="kit-items"
              data-testid="tbl-kit-items"
              className="OrderTemplateTable"
              size="small"
              columns={columns}
              dataSource={visible ? renderTreeNodes(state.template?.items) : []}
              pagination={false}
            />

            <h3>Fulfillment Type</h3>

            <Modal
              okText="Yes"
              cancelText="No"
              visible={showFulfillmentConfirmationModal}
              centered
              onOk={() => {
                handleFulfillmentTypeChange(fulfillmentType);
                setShowFulfillmentConfirmationModal(false);
              }}
              onCancel={() => {
                // Reset
                setFulfillmentType(state.template.fulfillmentType);
                setShowFulfillmentConfirmationModal(false);
              }}
            >
              Are you sure you want to change the fulfillment type?
            </Modal>

            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              defaultValue={OrderTemplateFulfillmentType.Tasso}
              options={[
                { label: 'Tasso', value: OrderTemplateFulfillmentType.Tasso },
                { label: 'BioTouch', value: OrderTemplateFulfillmentType.BioTouch },
              ]}
              value={state.template.fulfillmentType}
              onChange={(e) => {
                setFulfillmentType(e.target.value);
                setShowFulfillmentConfirmationModal(true);
              }}
            />

            {state.template.id !== defaultOrderTemplateId && (
              <h3>
                Customer Orderable Template<span> </span>
                <Checkbox checked={state.template.isCustomerOrderable} onChange={handleIsCustomerOrderableChange} />
              </h3>
            )}

            {
              // Only Show Option to Link R777 option if there is a usps toPatient label
              // Or if one was previously configured and the shipping method was changed so it can be deleted.
              (uspsToPatientLabelFound || r777LinkExists) && (
                <>
                  <h3>
                    Link Alternative R777 Template
                    {(!state.template.conditionalOrderTemplates || !r777LinkExists) && (
                      <Button type="link" onClick={() => selectAlternativeR777Template(undefined)}>
                        <PlusCircleOutlined />
                      </Button>
                    )}
                  </h3>

                  {
                    // Show conditional order template with edit/delete option
                    r777LinkExists && (
                      <>
                        {
                          templatesForProject.find(
                            (template) =>
                              template.templateId === (state.template.conditionalOrderTemplates! as any).R777
                          )?.name
                        }

                        <Button
                          shape="circle"
                          type="link"
                          onClick={() => selectAlternativeR777Template(undefined)}
                          icon={<EditOutlined />}
                        />

                        <Button
                          shape="circle"
                          type="link"
                          onClick={() => deleteR777Link()}
                          danger
                          icon={<DeleteOutlined />}
                        />
                        <br />
                      </>
                    )
                  }
                </>
              )
            }

            <h3>
              Custom Order Attributes
              <Button
                data-testid="button-add-custom-attribute"
                type="link"
                onClick={() => selectCustomAttribute(undefined)}
              >
                <PlusCircleOutlined />
              </Button>
            </h3>
            <Table
              style={{ width: '100%' }}
              size="small"
              columns={attributeColumns}
              dataSource={
                customAttributeDefinitions
                  .filter((attribute) => state.template?.customAttributeDefinitionIds?.includes(attribute.id))
                  .map((customAttribute) => ({
                    name: customAttribute.name,
                    key: customAttribute.id,
                    required: customAttribute.required,
                  })) ?? []
              }
              pagination={false}
            />
          </div>
        </Spinner>
      </Modal>
    </>
  );
};

export default OrderTemplateModal;
