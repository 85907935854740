import React, { FC, ReactElement, ReactNode } from 'react';

import { Divider, Typography, Space, Table, Tooltip } from 'antd';
import moment from 'moment';

import { WarningTwoTone } from 'components/icons';
import { DEVICE_STATUS, LAB_ADAPTER_KEYS, LAB_ORDER_STATUS, PATIENT_EXPERIENCE_STATUS } from 'constants/index';
import { IDeviceLot } from 'context/deviceLots';
import { IDevice } from 'context/devices';
import { IPatient } from 'context/patients';
import { DeviceEvent, IAuditChange } from 'utils/types';
import { ILabOrder } from 'context/labOrders';
import Collapse from 'components/Collapse';
import { Laboratory } from 'context/laboratories';
import { IProject } from 'context/projects';
import { ILabOrderEvent } from 'context/labOrderEvents';
import { FulfillmentOrder } from '@tassoinc/core-api-client';

interface IDeviceInformation {
  device: IDevice;
  patient: IPatient | null;
  labOrder: ILabOrder | null;
  labOrderEvents: ILabOrderEvent[] | null;
  laboratory: Laboratory | null;
  lot: IDeviceLot | null;
  events: DeviceEvent[];
  project: IProject | null;
  fulfillmentOrder: FulfillmentOrder | null;
}

const { Text, Title } = Typography;

const makeAddress = (patient: IPatient): string => {
  const street = `${patient.address1 || ''} ${patient.address2 || ''}`.trim();
  const endPart = `${patient.city || ''} ${patient.district1 || ''} ${patient.postalCode || ''}`.trim();

  return [street, endPart].filter((p) => p).join(', ');
};

const npiHtmlBuilder = (project: IProject | null | undefined, device: IDevice): ReactElement | null => {
  if (project?.purpose === 'research') {
    return (
      <Text>
        <strong>National Provider Identifier (NPI) not required for research projects</strong>
      </Text>
    );
  }
  if (
    project?.purpose === 'diagnosticTesting' &&
    project?.physicianApprovalMode &&
    project?.physicianApprovalMode !== 'none'
  ) {
    return (
      <Text>
        <div>
          <div>{device.npi ? `NPI ID: ${device.npi}` : 'NPI ID not set'}</div>
          <div>
            {device.providerFirstName
              ? `Provider First Name: ${device.providerFirstName}`
              : 'Provider First Name not set'}
          </div>
          <div>
            {device.providerLastName ? `Provider Last Name: ${device.providerLastName}` : 'Provider Last Name not set'}
          </div>
        </div>
      </Text>
    );
  }
  if (project?.purpose === 'notSpecified') {
    return (
      <Text>
        <div>
          Project purpose not set. The intended purpose of a project dictates whether National Provider Identifier (NPI)
          information must be present.
        </div>
      </Text>
    );
  }
  if (
    project?.purpose === 'diagnosticTesting' &&
    (!project?.physicianApprovalMode || project?.physicianApprovalMode === 'none')
  ) {
    return (
      <Text>
        <div>
          Physician approval mode not set. The physician approval mode determines if Tasso or the customer will be
          providing National Provider Identifier (NPI) information for patients within the project.
        </div>
      </Text>
    );
  }

  return null;
};

const DeviceInformation: FC<IDeviceInformation> = ({
  device,
  patient,
  labOrder,
  labOrderEvents,
  laboratory,
  lot,
  events,
  project,
  fulfillmentOrder,
}) => (
  <div className="DeviceInformation">
    <Title level={4}>Device</Title>

    <Space direction="vertical" size="small">
      <Text>
        <strong>ID: </strong>
        {device.id}
      </Text>

      <Text>
        <strong>Barcode: </strong>
        {device.barcode || ''}
      </Text>

      <Text>
        <strong>Activation code: </strong>
        {device.activationCode || ''}
      </Text>

      <Text>
        <strong>Activation status: </strong>
        {PATIENT_EXPERIENCE_STATUS[device.patientExperienceStatus as keyof typeof PATIENT_EXPERIENCE_STATUS] ||
          device.patientExperienceStatus ||
          ''}
      </Text>

      <Text>
        <strong>Lot: </strong>
        {lot?.key || ''}
      </Text>

      <Text>
        <strong>Expires at: </strong>
        {device.expiresAt ? moment(device.expiresAt).utc().format('YYYY-MM-DD') : ''}
      </Text>

      <Text>
        <strong>Status: </strong>
        {DEVICE_STATUS[device.status as keyof typeof DEVICE_STATUS]}
      </Text>

      <Text>
        <strong>To patient tracking: </strong>
        {device.trackingNumberToPatient || ''}
      </Text>

      <Text>
        <strong>To lab tracking: </strong>
        {device.trackingNumberToLab || ''}
      </Text>

      <strong>National Provider Identifier (NPI) information:</strong>
      {npiHtmlBuilder(project, device)}

      <Text>
        <Collapse
          items={[
            {
              title: (<div>Events ({events.length})</div>) as ReactNode,
              content: (
                <Table
                  key="outer-device-event"
                  expandable={{
                    expandRowByClick: true,
                    defaultExpandAllRows: false,
                    expandedRowRender: (record) => (
                      <Table
                        key={`inner-dev-ev ${record.id}`}
                        rowKey="fieldName"
                        pagination={false}
                        dataSource={
                          record.changes.map((change) => ({
                            fieldName: change.fieldName ?? '',
                            oldValue: change.oldValue ? JSON.stringify(change.oldValue) : '',
                            newvalue: change.newValue ? JSON.stringify(change.newValue) : '',
                          })) || []
                        }
                        columns={[
                          {
                            // indent below parent row
                            title: '',
                            dataIndex: '',
                            key: '',
                            width: '4%',
                          },
                          {
                            title: 'Field',
                            dataIndex: 'fieldName',
                            key: 'fieldName',
                            width: '20%',
                            render: (value) => <Text style={{ textTransform: 'capitalize' }}>{value}</Text>,
                          },
                          {
                            title: 'New Value',
                            dataIndex: 'newvalue',
                            key: 'newvalue',
                            width: '38%',
                          },
                          {
                            title: 'Old Value',
                            dataIndex: 'oldValue',
                            key: 'oldValue',
                            width: '38%',
                          },
                        ]}
                      />
                    ),
                    rowExpandable: (record) => record.changes && record.changes.length > 0,
                  }}
                  pagination={false}
                  dataSource={
                    events.map((event) => ({
                      id: event.id,
                      date: moment(event.occurredAt ?? event.createdAt)
                        .utc()
                        .format('YYYY-MM-DD'),
                      eventCode: event.shipperStatusCode,
                      eventName: event.shipperStatus,
                      status: DEVICE_STATUS[event.tassoStatus as keyof typeof DEVICE_STATUS],
                      changes: (event.modelChangeSet?.changes as IAuditChange[])?.filter(
                        (element) => element?.fieldName !== 'project' && element?.fieldName !== 'statusChangedAt'
                      ),
                      changedBy: event.modelChangeSet?.changedBy,
                    })) || []
                  }
                  columns={[
                    {
                      title: 'Date',
                      dataIndex: 'date',
                      key: 'date',
                    },
                    {
                      title: 'Status',
                      dataIndex: 'status',
                      key: 'status',
                    },
                    {
                      title: <Tooltip title="Number of changes to the device">Change Count</Tooltip>,
                      dataIndex: 'changes',
                      key: 'changes',
                      render: (changes) => <Text>{changes?.length ?? ''}</Text>,
                    },
                    {
                      title: 'Changed By',
                      dataIndex: 'changedBy',
                      key: 'changedBy',
                      render: (value) => <Text>{value}</Text>,
                    },
                  ]}
                  rowKey="id"
                />
              ) as ReactNode,
              extra: null,
              key: 'deviceEvents',
            },
          ]}
          initiallyCollapsed
        />
      </Text>
    </Space>

    <Divider />

    <Title level={4}>Recipient</Title>

    <Space direction="vertical" size="small">
      {patient ? (
        <>
          <Text>
            <strong>Full name: </strong>
            {`${patient.firstName || ''} ${patient.lastName || ''}`.trim()}
          </Text>
          <Text>
            <strong>Date of birth: </strong>
            {patient.dob ? moment(patient.dob).utc().format('YYYY-MM-DD') : ''}
          </Text>
          <Text>
            <strong>Address: </strong>
            {makeAddress(patient)}
          </Text>
          <Text>
            <strong>Phone: </strong>
            {patient.phoneNumber || ''}
          </Text>
          <Text>
            <strong>Email: </strong>
            {patient.email || ''}
          </Text>
        </>
      ) : (
        <div>
          <WarningTwoTone twoToneColor="red" /> Recipient information not found
        </div>
      )}
    </Space>

    <Divider />
    {fulfillmentOrder ? (
      <>
        <Title level={4}>Fulfillment Order</Title>

        <Space direction="vertical" size="small">
          <>
            <Text>
              <strong>Fulfillment Type: </strong>
              {fulfillmentOrder.fulfillmentType}
            </Text>
            <Text>
              <strong>Status: </strong>
              {fulfillmentOrder.status}
            </Text>
            <Text>
              <strong>Fulfillment Order Id: </strong>
              {fulfillmentOrder.id}
            </Text>
            <Text>
              <strong>Third Party Id: </strong>
              {fulfillmentOrder.thirdPartyId}
            </Text>
            <Text>
              <strong>Order Id: </strong>
              {fulfillmentOrder.orderId}
            </Text>
          </>
        </Space>

        <Divider />
      </>
    ) : null}
    <Title level={4}>Lab Order</Title>

    <Space direction="vertical" size="small">
      <>
        <Text>
          <strong>ID: </strong>
          {labOrder?.id || ''}
        </Text>
        <Text>
          <strong>Lab: </strong>
          {LAB_ADAPTER_KEYS[laboratory?.adapterKey as keyof typeof LAB_ADAPTER_KEYS] || ''}
        </Text>
        <Text>
          <strong>Status: </strong>
          {LAB_ORDER_STATUS[labOrder?.labStatus as keyof typeof LAB_ORDER_STATUS] || ''}
        </Text>
        <Text>
          <Collapse
            items={[
              {
                title: (<div>History ({labOrderEvents?.length || 0})</div>) as ReactNode,
                content: (
                  <Table
                    pagination={false}
                    dataSource={
                      // deviceEvents are in descending order, so for consistency we'll reverse the order history for display
                      (labOrderEvents || []).map((event) => ({
                        date: moment(event.createdAt).utc().format('YYYY-MM-DD'),
                        description: event.info?.description,
                        status: LAB_ORDER_STATUS[event.labStatus as keyof typeof LAB_ORDER_STATUS],
                      })) || []
                    }
                    columns={[
                      {
                        title: 'Date',
                        dataIndex: 'date',
                        key: 'date',
                        width: '30%',
                      },
                      {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                      },
                      {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                      },
                    ]}
                    rowKey="index"
                  />
                ) as ReactNode,
                extra: null,
                key: 'orderHistory',
              },
            ]}
            initiallyCollapsed
          />
        </Text>
      </>
    </Space>
  </div>
);

export default DeviceInformation;
