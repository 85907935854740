import React, { FC, useState, useEffect, useMemo } from 'react';

import { Alert, Button, Spin } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';

import { DeviceRow } from 'components/DevicesList/types';
import { DeviceDetailsModal, DevicesList } from 'components/index';
import { USER_ROLES } from 'constants/index';
import { useAuth } from 'context/auth';
import { useDeviceEvents } from 'context/deviceEvents';
import { useDeviceLots } from 'context/deviceLots';
import { useDevices } from 'context/devices';
import { IPatient, usePatients } from 'context/patients';
import { useProjects } from 'context/projects';
import { useLabOrderEvents } from 'context/labOrderEvents';
import { ILabOrder, useLabOrders } from 'context/labOrders';
import { useFulfillmentOrders } from 'context/fulfillmentOrders';

export interface DevicePagingConfig {
  page: number;
  pageLength: number;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  includeTotalCount: boolean;
  search: { by: string; term: string } | null;
}

const ProjectDevices: FC = () => {
  // ++ handle query string
  const { search } = useLocation();
  const filterByPatientId: string | undefined = search
    ? new URLSearchParams(search).get('patientId') || undefined
    : undefined;

  const filterByDeviceId: string | undefined = search
    ? new URLSearchParams(search).get('deviceId') || undefined
    : undefined;
  // -- handle query string

  const { projectId }: any = useParams();
  const { profile } = useAuth();
  const {
    getDevice,
    loadDevices,
    updateDevice,
    resetDevice,
    resetDevices,
    devices,
    device,
    loading: devicesLoading,
  } = useDevices();
  const { deviceLots, getDeviceLots, resetDeviceLots, loading: deviceLotsLoading } = useDeviceLots();
  const { getPatients, patients, resetPatients, loading: patientsLoading } = usePatients();
  const { project } = useProjects();
  const { deviceEvents, getDeviceEvents, loading: deviceEventsLoading, resetDeviceEvents } = useDeviceEvents();
  const { getLabOrders, loading: labOrdersLoading, resetLabOrders } = useLabOrders();
  const { fulfillmentOrders, getOrderFulfillmentOrders, resetFulfillmentOrders } = useFulfillmentOrders();
  const {
    labOrderEvents,
    getLabOrderEvents,
    loading: labOrderEventsLoading,
    resetLabOrderEvents,
  } = useLabOrderEvents();
  const [deviceDetailsModalVisible, setDeviceDetailsModalVisible] = useState(false);
  const [devicePagingConfig, setDevicePagingConfig] = useState<DevicePagingConfig | null>(null);
  const [totalDeviceCount, setTotalDeviceCount] = useState(0);
  const [deviceIdFilter, setDeviceIdFilter] = useState(filterByDeviceId || '');

  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const isCustomerService = (profile?.role || '') === USER_ROLES.internalCustomerService;

  const resetState = () => {
    resetDevices();
    resetPatients();
    resetDeviceLots();
    resetDeviceEvents();
    resetLabOrders();
    resetLabOrderEvents();
    resetFulfillmentOrders();
  };

  const loadDeviceDataset = async (includeTotal?: boolean) => {
    if (!devicePagingConfig) {
      resetState();
      return;
    }

    const includeTotalCount = typeof includeTotal === 'boolean' ? includeTotal : devicePagingConfig.includeTotalCount;

    const statusFilter = isAdmin || isCustomerService ? {} : { statuses: ['readyToShip', 'awaitingPickup'] };

    loadDevices(
      {
        ...devicePagingConfig,
        ...(filterByPatientId ? { patientIds: [filterByPatientId] } : {}),
        ...statusFilter,
        projectId,
        ...(deviceIdFilter ? { ids: [deviceIdFilter] } : {}),
        includeTotalCount,
        headers: { 'tasso-hydration': 'labOrder,laboratory,order' },
      },
      async (loadedDevices, paging) => {
        if (!loadedDevices) {
          resetState();
          return;
        }

        if (paging && includeTotalCount) {
          setTotalDeviceCount(paging.totalCount);
        }

        const patientIds = loadedDevices.map((d) => d.patientId).filter((id) => !!id);
        if (patientIds.length > 0) {
          const uniquePatientIds = [...new Set(patientIds)];
          const req: Record<string, string> = { ids: uniquePatientIds.join(',') };
          await getPatients(req);
        } else {
          resetPatients();
        }
      }
    );
  };

  useEffect(() => {
    getDeviceLots();

    return () => {
      resetState();
    };
  }, []);

  const openDeviceDetailsModal = () => {
    setDeviceDetailsModalVisible(true);
  };

  const closeDeviceDetailsModal = () => {
    setDeviceDetailsModalVisible(false);
    resetDevice();
  };

  const handleDeviceClick = (clickedDevice: DeviceRow) => {
    const { id, order } = clickedDevice;
    getDevice(id);
    getDeviceEvents(id);
    getLabOrders(id, (labOrders: ILabOrder[]) => {
      resetLabOrderEvents();
      if (labOrders.length > 0) {
        getLabOrderEvents(labOrders[0].id);
      }
    });
    if (order) {
      getOrderFulfillmentOrders(order.id);
    }
    openDeviceDetailsModal();
  };

  const handleDeviceUpdate = async (deviceId: string, values: Record<string, string | null>): Promise<void> => {
    await updateDevice(deviceId, values);
  };

  const getPatient = (patientId: string): IPatient => patients.find((p) => p.id === patientId)!;

  const isLoading = useMemo(
    (): boolean => patientsLoading || devicesLoading || deviceLotsLoading,
    [patientsLoading, devicesLoading, deviceLotsLoading]
  );

  useEffect(() => {
    if (devicePagingConfig) {
      loadDeviceDataset();
    }
  }, [devicePagingConfig]);

  useEffect(() => {
    loadDeviceDataset(true);
  }, [deviceIdFilter]);
  return (
    <div className="ProjectDevices">
      {!!filterByDeviceId && (
        <Alert
          showIcon
          style={{ margin: '10px 10px 0 10px', width: 'calc(100% - 20px)' }}
          type="info"
          message={
            <>
              Showing one device.{' '}
              <Link
                to={`/projects/${projectId}/devices`}
                onClick={() => {
                  setDeviceIdFilter('');
                }}
              >
                <Button type="link" size="small">
                  Show all devices
                </Button>
              </Link>
            </>
          }
        />
      )}

      <DevicesList
        devices={devices}
        deviceLots={deviceLots}
        patients={patients}
        onDeviceUpdate={handleDeviceUpdate}
        loading={isLoading}
        onDeviceClick={handleDeviceClick}
        isFiltered={!!filterByPatientId}
        projectId={projectId}
        patientExperienceEnabled={project!.patientExperienceEnabled}
        updatePagingConfig={(c: Partial<DevicePagingConfig>) => setDevicePagingConfig((s) => ({ ...s!, ...c }))}
        totalDeviceCount={totalDeviceCount}
        devicePagingConfig={devicePagingConfig}
      />

      {device !== null && deviceEventsLoading && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%' }}>
          <Spin
            size="large"
            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translateXY(-50%,-50%)' }}
          />
        </div>
      )}
      {device !== null && !deviceEventsLoading && !labOrdersLoading && !labOrderEventsLoading && (
        <DeviceDetailsModal
          device={device}
          patient={getPatient(device.patientId)}
          project={project}
          events={deviceEvents}
          labOrderEvents={labOrderEvents}
          lot={
            deviceLots.length > 0 && device.deviceLotId
              ? deviceLots.find((lot) => lot.id === device.deviceLotId) || null
              : null
          }
          fulfillmentOrder={fulfillmentOrders ? fulfillmentOrders[0] : null}
          visible={deviceDetailsModalVisible}
          onCancel={closeDeviceDetailsModal}
        />
      )}
    </div>
  );
};

export default ProjectDevices;
