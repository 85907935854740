import React, { FC, useState, useEffect, useMemo, useRef } from 'react';

import { Modal, InputNumber, Select, Form, FormInstance } from 'antd';
import { IProject } from 'context/projects';
import { OrderTemplate, Site } from '@tassoinc/core-api-client';
import { useOrderTemplates } from 'context/orderTemplates';
import { useSites } from 'context/sites';
import { getDefaultOrderTemplate } from 'utils/orderTemplates';
import { CreateOrderPayload } from 'api/orders';
import Notification from 'utils/notification';
import { useOrders } from 'context/orders';

type Props = {
  open?: boolean;
  loading: boolean;
  onClose: () => void;
  project: IProject | null;
  orderTemplates: OrderTemplate[];
  sites: Site[];
};

const CreateBulkOrderModal: FC<Props> = ({ open, loading, onClose, project }) => {
  const [value, setValue] = useState<number>(0);
  const form = useRef<FormInstance>(null);
  const { orderTemplates, getOrderTemplatesForProject, resetOrderTemplates } = useOrderTemplates();
  const { sites, getSitesForCustomer, resetSites } = useSites();
  const { createOrder } = useOrders();

  const handleSubmit = (): void => {
    form.current
      ?.validateFields()
      .then((values: CreateOrderPayload) => {
        const withProject = { ...values, projectId: project?.id };
        createOrder(withProject);
      })
      .catch((e: any) => {
        console.log(e);
        Notification({ type: 'error', message: e.message });
      });

    onClose();
  };

  useEffect(() => {
    if (open) {
      form.current?.setFieldsValue({
        templateName: '',
        siteId: '',
        quantity: '',
      });
    }
  }, [open]);

  useEffect(() => {
    getOrderTemplatesForProject(project!.id);
    getSitesForCustomer(project!.customerId);

    return () => {
      resetOrderTemplates();
      resetSites();
    };
  }, []);

  const defaultOrderTemplate = useMemo(() => {
    const defaultTemplate = getDefaultOrderTemplate(orderTemplates, project!.orderTemplatesViewOrder);

    return defaultTemplate;
  }, [orderTemplates, project]);

  return (
    <Modal
      wrapClassName="Modal Normal"
      centered
      forceRender
      width="460px"
      visible={open}
      title="Create Unassigned Orders"
      onCancel={onClose}
      cancelText="Cancel"
      okText={`Create ${value} device${value === 1 ? '' : 's'}`}
      onOk={handleSubmit}
      confirmLoading={loading}
      okButtonProps={{ disabled: loading || value === 0 }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Form ref={form} layout="vertical" initialValues={{ orderTemplateId: defaultOrderTemplate?.id }}>
        <p>Orders placed using this modal will be linked to this project but will not be assigned to a patient.</p>

        <Form.Item name="orderTemplateId" label="Order Configuration">
          <Select placeholder="Please select an order configuration">
            {orderTemplates
              .filter((template) => !template.isCustomerOrderable === false)
              .map((orderTemplate) => (
                <Select.Option key={orderTemplate.id} value={orderTemplate.id}>
                  {orderTemplate.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="siteId" label="Site">
          <Select placeholder="Please select a site">
            {sites.map((site) => (
              <Select.Option key={site.id} value={site.id}>
                {site.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="quantity" label="Quantity">
          <InputNumber
            size="large"
            onChange={(e) => {
              setValue(Number.isNaN(e) || e! < 1 ? 0 : e!);
            }}
            disabled={loading}
            bordered
            min={0}
            max={1000}
            step={value < 100 ? 10 : 20}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateBulkOrderModal;
