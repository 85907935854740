/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState, useMemo } from 'react';

import { Form } from 'antd';
import moment from 'moment';

import PatientModal from 'components/PatientModal';
import { useOrders } from 'context/orders';
import { IPatient, usePatients } from 'context/patients';
import { useProjectLaboratories } from 'context/projectLaboratories';
import { CustomAttributeDefinitionProject, useProjects } from 'context/projects';
import { ICustomAttributeDefinition } from 'context/customAttributeDefinitions';
import { useOrderTemplates } from 'context/orderTemplates';
import { DISTRIBUTION_MODEL_SOURCES, NO_ORDER_PLEASE } from 'constants/index';
import { getDefaultOrderTemplate, sortOrderTemplates } from 'utils/orderTemplates';

interface AddPatientData {
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  district1?: string;
  dob?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  assignedSex?: string;
  lastName?: string;
  orderingPhysicianFirstName?: string;
  orderingPhysicianLastName?: string;
  orderingPhysicianNpiId?: string;
  customAttributeValues?: Record<string, any>;
  deviceBarcode?: string;
  phoneNumber?: string;
  postalCode?: string;
  projectId?: string;
  subjectId?: string;
  orderTemplateId?: string;
}

const usePatientModal: any = () => {
  const { createPatient, updatePatient, deletePatient, loading } = usePatients();
  const { createOrder } = useOrders();
  const { getProjectLaboratories } = useProjectLaboratories();
  const { getOrderTemplatesForProject, orderTemplates } = useOrderTemplates();
  const { project } = useProjects();
  const [form] = Form.useForm();
  const [patientModalVisible, setPatientModalVisible] = useState(false);
  const [patientModalPopulated, setPatientModalPopulated] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<IPatient | null>(null);
  const [laboratoriesLoaded, setLaboratoriesLoaded] = useState(false);
  const [successCallback, setSuccessCallback] = useState<any>(null);
  const [customAttributeDefinitions, setCustomAttributeDefinitions] = useState<
    Map<string, ICustomAttributeDefinition[] | CustomAttributeDefinitionProject[]>
  >(new Map<string, ICustomAttributeDefinition[]>());

  const openPatientModal = () => {
    setPatientModalVisible(true);
  };

  const closePatientModal = () => {
    setPatientModalVisible(false);
    form.resetFields();
    setSelectedPatient(null);
    setSuccessCallback(null);
  };

  const callback = async () => {
    setPatientModalPopulated(true);
    closePatientModal();
    if (successCallback) {
      await successCallback();
    }
  };

  const afterCreatePatientCallback = async (patient: IPatient, data: AddPatientData) => {
    if (project!.distributionModel === DISTRIBUTION_MODEL_SOURCES.atCustomerSite) {
      await callback();
      return;
    }
    // User selected no order to be placed
    if (data.orderTemplateId === NO_ORDER_PLEASE) {
      await callback();
      return;
    }
    let npiInformation = {};
    if (data.orderingPhysicianNpiId && data.orderingPhysicianFirstName && data.orderingPhysicianLastName) {
      npiInformation = {
        customerProvidedNpi: data.orderingPhysicianNpiId,
        customerProvidedProviderFirstName: data.orderingPhysicianFirstName,
        customerProvidedProviderLastName: data.orderingPhysicianLastName,
      };
    }

    await createOrder(
      {
        patientId: patient.id,
        ...(data.deviceBarcode ? { deviceBarcode: data.deviceBarcode } : {}),
        ...npiInformation,
        ...(data.customAttributeValues ? { customAttributeValues: data.customAttributeValues } : {}),
        orderTemplateId: project!.useOrderTemplates ? `${data.orderTemplateId}` : undefined,
      },
      {},
      callback
    );
  };

  const handleCreatePatient = (patient: IPatient) => {
    const keysWithValues = Object.keys(patient).filter((key) => patient[key as keyof typeof patient] !== '');
    const data: AddPatientData = keysWithValues.reduce(
      (acc, key) => ({ ...acc, [key]: patient[key as keyof typeof patient] }),
      {}
    );
    const withoutOrderTemplateId = { ...data };
    delete withoutOrderTemplateId.orderTemplateId;
    createPatient(withoutOrderTemplateId, async (newPatient) => afterCreatePatientCallback(newPatient, data));
  };

  const handleEditPatient = (patient: IPatient) => {
    if (!selectedPatient) {
      return;
    }

    // Send undefined for all the patient properties that are 'hidden', null for the visible ones.
    const patientData = {
      lastName: patient.lastName || null,
      address1: patient.address1 || null,
      city: patient.city || null,
      district1: patient.district1 || null,
      postalCode: patient.postalCode || null,
      country: patient.country || null,
      firstName: patient.firstName || (project?.patientPropertyConfig?.firstName === 'hidden' ? undefined : null),
      dob: patient.dob || (project?.patientPropertyConfig?.dob === 'hidden' ? undefined : null),
      gender: patient.gender || (project?.patientPropertyConfig?.gender === 'hidden' ? undefined : null),
      assignedSex: patient.assignedSex || (project?.patientPropertyConfig?.assignedSex === 'hidden' ? undefined : null),
      subjectId: patient.subjectId || (project?.patientPropertyConfig?.subjectId === 'hidden' ? undefined : null),
      address2: patient.address2 || (project?.patientPropertyConfig?.address2 === 'hidden' ? undefined : null),
      phoneNumber: patient.phoneNumber || (project?.patientPropertyConfig?.phoneNumber === 'hidden' ? undefined : null),
      email: patient.email || (project?.patientPropertyConfig?.email === 'hidden' ? undefined : null),
      smsConsent: patient.smsConsent || null,
    };

    updatePatient(selectedPatient.id, patientData, callback);
  };

  const handleDeletePatient = (id: any) => {
    deletePatient(id, callback);
  };

  /* ----------------------- FOR EXPORT ------------------------- */

  const onCreatePatient = (
    attributes: Map<string, ICustomAttributeDefinition[] | CustomAttributeDefinitionProject[]>,
    onSuccess: any = null
  ) => {
    setCustomAttributeDefinitions(attributes);
    if (!laboratoriesLoaded) {
      getProjectLaboratories(project!.id);
      getOrderTemplatesForProject(project!.id);
      setLaboratoriesLoaded(true);
    }
    setPatientModalPopulated(false);
    setSelectedPatient(null);
    form.setFieldsValue({});
    openPatientModal();

    if (onSuccess) {
      setSuccessCallback(() => onSuccess);
    } else {
      setSuccessCallback(null);
    }
  };

  const onEditPatient = async (patient: IPatient) => {
    setCustomAttributeDefinitions(new Map<string, ICustomAttributeDefinition[]>([]));

    setPatientModalPopulated(false);
    setSelectedPatient(patient);
    form.setFieldsValue({
      ...patient,
      dob: patient.dob ? moment(patient.dob, 'YYYY-MM-DD') : '',
      smsConsent: patient.smsConsent !== '',
    });
    openPatientModal();
  };

  const onDeletePatient = (id: any) => {
    setPatientModalPopulated(false);
    handleDeletePatient(id);
  };

  useEffect(() => {
    const defaultTemplate = getDefaultOrderTemplate(orderTemplates, project!.orderTemplatesViewOrder);

    const orderTemplateId = defaultTemplate?.id;

    if (orderTemplateId) {
      const values = form.getFieldsValue();
      form.setFieldsValue({ ...values, orderTemplateId });
    }
  }, [orderTemplates, project]);

  const sortedOrderTemplates = useMemo(
    () => sortOrderTemplates(orderTemplates, project!.orderTemplatesViewOrder),
    [orderTemplates, project]
  );

  const Modal = (
    <PatientModal
      form={form}
      patient={selectedPatient}
      visible={patientModalVisible}
      project={project!}
      orderTemplates={sortedOrderTemplates}
      customAttributeDefinitions={customAttributeDefinitions}
      loading={loading}
      onOk={selectedPatient ? handleEditPatient : handleCreatePatient}
      onCancel={closePatientModal}
    />
  );

  return { Modal, onCreatePatient, onEditPatient, onDeletePatient, patientModalPopulated };
};

export default usePatientModal;
