/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Key, useEffect, useState } from 'react';

import { Button, Dropdown, Menu, Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table/interface';

import { EditOutlined, EllipsisOutlined, InfoCircleOutlined, UserAddOutlined } from 'components/icons';
import { DEVICE_STATUS } from 'constants/index';
import useBackendColumnFilter from 'hooks/useBackendColumnFilter';
import useColumnSearch from 'hooks/useBackendColumnSearch';

import { DevicePagingConfig } from './index';

export interface TableRow {
  id: string;
  status: string;
  barcode: string;
  lot: string;
  expiresAt: string;
  trackingNumberToLab: string;
}

/**
 * @param date {string} date as a string in the `YYYY-MM-DD` format. E.g., `2001-11-20`
 * @returns {string} timestamp in milliseconds
 */
export const dateToTimestamp = (date: string): number => {
  const y = parseInt(date.substr(0, 4), 10);
  const m = parseInt(date.substr(4, 2), 10) - 1; // we need month index here which starts at 0
  const d = parseInt(date.substr(-2), 10);

  const theDate = new Date(y, m, d, 0, 0, 0, 0);

  return theDate.getTime();
};

interface Props {
  items: TableRow[];
  loading: boolean;
  selectedRowKeys: string[];
  onRowSelect: (selectedRowKeys: Key[], selectedRows: TableRow[]) => void;
  onEditClick: (deviceIndex: number) => void;
  onAssignClick: (deviceIndex: number) => void;
  onDetailsClick: (deviceIndex: number) => void;
  updatePagingConfig: (config: Partial<DevicePagingConfig>) => void;
  totalDeviceCount: number;
  devicePagingConfig: DevicePagingConfig | null;
}

const DeviceList: React.FC<Props> = ({
  items,
  loading,
  selectedRowKeys,
  onRowSelect,
  onEditClick,
  onAssignClick,
  onDetailsClick,
  updatePagingConfig,
  totalDeviceCount,
  devicePagingConfig,
}) => {
  const { getColumnSearchProps } = useColumnSearch<TableRow>();
  const { getColumnSearchProps: getColumnFilterProps } = useBackendColumnFilter<TableRow>();

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>({
    current: 0,
    defaultCurrent: 1,
    pageSizeOptions: ['10', '20', '50', '100'],
    defaultPageSize: 100,
    hideOnSinglePage: false,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (n) => `Total ${n} devices`,
  });

  const resetPagination = () => {
    updatePagingConfig({
      page: tablePagination.defaultCurrent!,
      pageLength: tablePagination.defaultPageSize!,
      sortBy: 'createdAt',
      sortOrder: 'desc',
      includeTotalCount: true,
      search: null,
    });

    setTablePagination((s) => ({
      ...s,
      current: tablePagination.defaultCurrent!,
      pageSize: tablePagination.defaultPageSize!,
    }));
  };

  const searchConfig = devicePagingConfig?.search || null;

  const searchByKey = async (searchKey: string, term: string | null) => {
    if (searchConfig !== null && searchConfig.by === searchKey) {
      // search previously performed on this field, now reset or update it
      if (term === null) {
        resetPagination();
      } else {
        updatePagingConfig({ search: { by: searchKey, term }, page: 1, includeTotalCount: true });
      }
    } else if (term) {
      updatePagingConfig({ search: { by: searchKey, term }, page: 1, includeTotalCount: true });
      setTablePagination((s) => ({ ...s, current: 1 }));
    }
  };

  const columns: ColumnType<TableRow>[] = [
    {
      key: 'barcode',
      dataIndex: 'barcode',
      title: 'Barcode',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps(searchConfig, 'barcodeLike', searchByKey),
    },
    {
      key: 'lot',
      dataIndex: 'lot',
      title: 'Lot',
    },
    {
      key: 'expiresAt',
      dataIndex: 'expiresAt',
      title: 'Expires',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps(searchConfig, 'expiresAt', searchByKey),
    },
    {
      key: 'status',
      title: 'Status',
      render: (_value, record) => (
        <>{record.status ? DEVICE_STATUS[record.status as keyof typeof DEVICE_STATUS] || record.status : ''}</>
      ),
      ...getColumnFilterProps(
        searchConfig,
        'statuses',
        [
          { label: 'In stock', value: 'inStock' },
          { label: 'Ready to ship', value: 'readyToShip' },
          { label: 'Awaiting pickup', value: 'awaitingPickup' },
        ],
        searchByKey
      ),
    },
    {
      key: 'trackingNumberToLab',
      dataIndex: 'trackingNumberToLab',
      title: 'Tracking number',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps(searchConfig, 'trackingNumberToLabLike', searchByKey),
    },
    {
      key: 'action',
      title: '',
      width: '150px',
      align: 'center',
      render: (_value, _record, index) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="details" onClick={() => onDetailsClick(index)} icon={<InfoCircleOutlined />}>
                View details
              </Menu.Item>
              <Menu.Item key="assign" onClick={() => onAssignClick(index)} icon={<UserAddOutlined />}>
                Assign to patient
              </Menu.Item>
              <Menu.Item key="edit" onClick={() => onEditClick(index)} icon={<EditOutlined />}>
                Edit
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button icon={<EllipsisOutlined style={{ transform: 'rotate(90deg)', height: '16px' }} />} type="default" />
        </Dropdown>
      ),
    },
  ];

  const onTableChange = (pagination: TablePaginationConfig, _filters: any, sorter: any) => {
    setTablePagination((s) => ({ ...s, current: pagination.current, pageSize: pagination.pageSize }));

    const sortBy = sorter.columnKey || 'createdAt';
    const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

    const shouldUpdateSettings =
      devicePagingConfig === null ||
      devicePagingConfig.page !== pagination.current ||
      devicePagingConfig.pageLength !== (pagination.pageSize || pagination.defaultPageSize) ||
      devicePagingConfig.sortBy !== sortBy ||
      devicePagingConfig.sortOrder !== sortOrder;

    if (shouldUpdateSettings) {
      updatePagingConfig({
        page: pagination.current!,
        pageLength: pagination.pageSize!,
        sortBy,
        sortOrder,
        includeTotalCount: devicePagingConfig === null || devicePagingConfig.page === 1,
      });
    }
  };

  useEffect(() => {
    setTablePagination((s) => ({ ...s, total: totalDeviceCount }));
  }, [totalDeviceCount]);

  useEffect(() => {
    resetPagination();
  }, []);

  return (
    <Table
      className="UnassignedDevicesList"
      rowKey="id"
      columns={columns}
      dataSource={items}
      loading={loading}
      showSorterTooltip={false}
      size="small"
      bordered
      rowSelection={{
        selectedRowKeys,
        onChange: onRowSelect,
      }}
      pagination={tablePagination}
      onChange={onTableChange}
    />
  );
};

export default DeviceList;
