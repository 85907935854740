import React, { FC, useEffect, useState } from 'react';

import { Modal, Select } from 'antd';

import { OrderTemplate } from '@tassoinc/core-api-client';

interface Props {
  orderTemplates: OrderTemplate[];
  // The order template that is linking the alternative template.
  linkingTemplate: OrderTemplate | undefined;
  visible: boolean;
  working: boolean;
  onOk: (selectedTemplate: string) => Promise<void>;
  onCancel: () => void;
}

const OrderTemplateAlternativeR777SelectModal: FC<Props> = ({
  orderTemplates,
  visible,
  working,
  linkingTemplate,
  onOk,
  onCancel,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');

  useEffect(() => {
    setSelectedTemplate('');
  }, [visible]);

  const handleSubmit = () => {
    if (selectedTemplate) {
      onOk(selectedTemplate);
    }
  };

  const handleChange = (templateId: string): void => {
    setSelectedTemplate(templateId);
  };

  return (
    <Modal
      wrapClassName="Modal OrderTemplateSelectModal"
      visible={visible}
      title="Select order template"
      okText="Select template"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      confirmLoading={working}
      centered
      width="400px"
      forceRender
      okButtonProps={{ disabled: selectedTemplate.length === 0 }}
    >
      <Select
        className="multiSelect"
        showSearch
        showArrow
        value={selectedTemplate}
        onChange={handleChange}
        placeholder="Select template"
        style={{ width: '100%' }}
        loading={working}
      >
        {orderTemplates
          .filter((orderTemplate) => orderTemplate.id !== linkingTemplate?.id)
          .map((item) => (
            <Select.Option key={item.id} value={item.templateId} data-testid={item.name}>
              {item.name}
            </Select.Option>
          ))}
      </Select>
    </Modal>
  );
};

export default OrderTemplateAlternativeR777SelectModal;
